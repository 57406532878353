<template>
    <div>
        <div class="d-flex justify-content-center">
            <b-overlay :show="!ready">
                <div class="d-flex flex-column align-items-center" v-if="ready">
                    <h4>Membership Completion</h4>
                    <div class="align-items-center">
                        <img src="@/assets/images/darkroots_transparent.webp" alt height="150" />
                    </div>
        
                    <h3>Hi {{ completion_data.first_name }}</h3>
                    <span>Please read through the membership agreement and confirm your acceptance to join the collective</span>
                    <span>A copy will be mailed to you on acceptance</span>
                    
                    <hr />
                    <div>
                        <!--- <iframe id="pdf-iframe" :src="completion_data.agreement_src"></iframe> -->
                        <webix-ui :config="ui"></webix-ui>
                        
                        <div class="modal-footer mt-4">
                            <b-button @click="confirmMembership" variant="primary">Confirm Membership</b-button>
                        </div>
                    </div>
                </div>
                
            </b-overlay>
        </div>
    </div>
</template>

<script>
import { crmMethods, crmComputed } from "@/state/helpers";
//eslint-disable-next-line
import Swal from "sweetalert2";
export default {
    data: () => ({
        ready: false,
    }),
    computed: {
        ...crmComputed,
        ui() {
            const viewportWidth = window.innerWidth;
            let config = {
                rows:[
                    { 
                        view:"pdfbar", 
                        id:"toolbar", 
                        width: (viewportWidth > 768 ? 600 : 350),
                    },
                    { 
                        view:"pdfviewer", 
                        id:"pdf",
                        toolbar:"toolbar",
                        height: (viewportWidth > 768 ? 600 : 350), 
                        url:"binary->" + this.completion_data.agreement_src
                    }
                ]
            }
            return config;
        }
    },
    methods: {
        ...crmMethods,
        refreshPage() {
            window.location.reload();
        },
        confirmMembership() {
            Swal.fire({
                title: `I, ${this.completion_data.first_name} ${this.completion_data.last_name} (ID No. ${this.completion_data.id_no})`,
                text: `Confirm that I accept the Dark Roots Collective End User Agreement` ,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes!",
            }).then((result) => {
                //eslint-disable-next-line
                if (result.value) {
                    this.finaliseMembership({token: this.$route.params.token}).then(() => {
                        Swal.fire(`Account Created`, "", "success");
                        window.location.href = 'https://members.darkroots.club/';
                    });
                }
            });
        }
    },
    mounted() {
        this.getMembershipCompletionData(this.$route.params.token).then(() => {
            this.ready = true;
        });
    },
};
</script>

<style lang="scss" scoped>
.mw-500 {
    max-width: 500px !important;
}
.input-500 {
    width: 500px;
}

@media only screen and (max-width: 510px) {
    .input-500 {
        width: 300px;
    }
}
@media only screen and (max-width: 310px) {
    .input-500 {
        width: 200px;
    }
}

.pdf-view {
    width: 600px;
    height: 600px; /* Default height */
    border: none;
}

@media (max-width: 768px) {
    .pdf-view  {
        width: 300px;
        height: 300px; /* Adjust height for smaller devices */
    }
}
</style>
