<script>
import Layout from "../../layouts/no-menu/main-no-menu";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import completeMembership from "@/components/crm/completeMembership.vue";

//import { crmMethods, crmComputed } from "@/state/helpers";

/**
 * Starter component
 */
export default {
    page: {
        title: "Complete Membership",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader, completeMembership },
    computed: {
       // ...crmComputed,
    },
    data() {
        return {
            title: "Complete Membership",
            items: [],
        };
    },
};
</script>
<template>
    <Layout>
        <PageHeader />
        <completeMembership />
    </Layout>
</template>
